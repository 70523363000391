import { addDoc, collection } from "firebase/firestore";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import { toast } from "react-hot-toast";
import * as Yup from "yup";
import { db } from "../firebase/firebase";
import { Helmet } from "react-helmet";

function Contact() {
  const handleSubmit = async (values, { resetForm }) => {
    try {
      // // Check if the mobile number already exists in the database
      // const mobileQuery = query(collection(db, 'contact us'), where('mobile', '==', parseInt(values.mobile)));
      // const mobileSnapshot = await getDocs(mobileQuery);
      // console.log(mobileSnapshot, "Her is the snapshot");
      // // If the mobile number already exists, display an error message and return
      // if (!mobileSnapshot.empty) {
      //   toast.error('This mobile number already exists in the database. Please use a different number.', { duration: 5000 });
      //   return;
      // }
      // Add form data to Firestore
      await addDoc(collection(db, "contact us"), {
        name: values.name,
        email: values.email,
        message: values.message,
        mobile: parseInt(values.mobile),
        service: values.service,
        timestamp: new Date(),
      });

      // Optionally, show a success message or redirect the user
      toast.success("Form submitted successfully. We will reach you out soon", {
        duration: 5000,
      });
    } catch (error) {
      console.error("Error submitting form:", error);
      // Optionally, show an error message to the user
      toast.error(
        "An error occurred while submitting the form. Please try again later.",
        { duration: 5000 }
      );
    }
  };

  return (
    <>
      <Helmet>
        <title>Contact Us | Wisemarkaura</title>
        <meta
          name="description"
          content="Professional psychological services to help you overcome challenges and improve your mental well-being. Contact us today to schedule a confidential appointment."
        />
      </Helmet>

      <section className="max-w-screen-xl mx-auto px-4">
        <div className="my-36">
          <h1
            style={{ fontFamily: "bespoke-bold" }}
            className="text-[#4C7C7C] text-4xl sm:text-5xl font-bold"
          >
            Contact Us
          </h1>
          <p className="mt-4 font-inter text-lg text-gray-700 font-medium">
            At Wisemark Aura, we understand that every individual's journey is
            unique. That's why we offer personalized, evidence-based
            interventions to address your specific needs and goals. Take the
            first step towards a happier, more fulfilling life.
          </p>
          <p className="mt-2 font-inter text-lg text-gray-700 font-medium">
            Book Your Free Discovery Session today!
          </p>
          <Formik
            initialValues={{
              name: "",
              email: "",
              mobile: "",
              message: "",
              service: "",
            }}
            validationSchema={Yup.object({
              name: Yup.string()
                .min(2, "Name must be at least 2 characters")
                .max(50, "Name must be at most 50 characters")
                .required("Name is required"),
              email: Yup.string()
                .email("Invalid email address")
                .required("Email is required"),
              message: Yup.string()
                .min(10, "Message must be at least 10 characters")
                .max(500, "Message must be at most 500 characters")
                .required("Message is required"),
              service: Yup.string().required("Service selection is required"),
              mobile: Yup.string()
                .required("Mobile number is required")
                .matches(
                  /^[0-9]{10}$/,
                  "Mobile number must be 10 digits and numbers only"
                ),
            })}
            onSubmit={(values, { resetForm }) => {
              // Your form submission logic goes here
              console.log(values);
              handleSubmit(values, resetForm);
              resetForm();
            }}
          >
            {(formik) => (
              <Form className="mt-8 max-w-4xl">
                <div className="flex flex-col sm:flex-row gap-5 justify-between items-stretch">
                  <div className="w-full">
                    <p className="ml-1 font-semibold font-inter">Name</p>
                    <Field
                      type="text"
                      name="name"
                      className="rounded-lg p-2 mt-2 border-2 border-[#4C7C7C] focus:outline-[#4C7C7C] w-full"
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <div className="w-full">
                    <p className="ml-1 font-semibold font-inter">Email</p>
                    <Field
                      type="email"
                      name="email"
                      className="rounded-lg p-2 mt-2 border-2 border-[#4C7C7C] focus:outline-[#4C7C7C] w-full"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row gap-5 justify-between items-stretch mt-5">
                  <div className="w-full">
                    <p className="ml-1 font-semibold font-inter">
                      Mobile Number
                    </p>
                    <Field
                      type="tel"
                      name="mobile"
                      className="rounded-lg p-2 mt-2 border-2 border-[#4C7C7C] focus:outline-[#4C7C7C] w-full"
                    />
                    <ErrorMessage
                      name="mobile"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <div className="w-full">
                    <p className="ml-1 font-semibold font-inter">
                      What services would you like to enquire about?
                    </p>
                    <Field
                      as="select"
                      name="service"
                      className="rounded-lg p-2 mt-2 border-2 border-[#4C7C7C] focus:outline-[#4C7C7C] w-full"
                    >
                      <option value="">Select</option>
                      <option value="Free Discovery Session">
                        Free Discovery Session
                      </option>
                      <option value="Individual Session">
                        Individual Session (PAID)
                      </option>
                      <option value="6 Individual Sessions">
                        6 Individual Sessions (PAID)
                      </option>
                    </Field>
                    <ErrorMessage
                      name="service"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                </div>
                <div className="w-full mt-5">
                  <p className="ml-1 font-semibold font-inter">Message</p>
                  <Field
                    as="textarea"
                    name="message"
                    rows={5}
                    className="rounded-lg p-2 mt-2 border-2 border-[#4C7C7C] focus:outline-[#4C7C7C] w-full"
                  />
                  <ErrorMessage
                    name="message"
                    component="div"
                    className="text-red-500"
                  />
                </div>
                <button
                  type="submit"
                  className="mt-5 text-black bg-[#8CC7C6] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm px-4 py-2 text-center"
                >
                  Book Session
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </section>
    </>
  );
}

export default Contact;
