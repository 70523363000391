import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import FlipCard from "../common/FlipCard";
import process from  '../assets/images/process.png';
function Services({ setShowBookingModal, showBookingModal }) {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <>
      <Helmet>
        <title>Psychologist Services | Wisemarkaura</title>
        <meta
          name="description"
          content="Professional psychological services to help you overcome challenges and improve your mental well-being. Contact us today to schedule a confidential appointment."
        />
        <meta
          name="keywords"
          content="psychologist, therapy, counseling, mental health, well-being, appointments"
        />
      </Helmet>

      <section className="max-w-screen-xl mx-auto px-4">
        <div className="mt-36">
          <h1
            style={{ fontFamily: "bespoke-bold" }}
            className="text-[#4C7C7C] text-center text-4xl sm:text-7xl font-bold"
          >
            Our Services
          </h1>
          <div className="flex flex-wrap justify-center gap-6  mt-4">
            <FlipCard
              title="Stress Management Therapy"
              description="In today's fast-paced world, stress can take a toll on your mental and physical health. Our stress management therapy equips you with effective coping strategies to manage stress, reduce anxiety, and improve overall well-being. Through evidence-based techniques such as cognitive-behavioral therapy (CBT), mindfulness, and relaxation exercises, we help you develop resilience and maintain a healthy work-life balance."
            />
            <FlipCard
              title="Anger Management Counseling"
              description="Unresolved anger can negatively impact your relationships, productivity, and overall quality of life. Our anger management counseling provides you with the tools to identify triggers, develop healthy coping mechanisms, and effectively communicate your emotions. We employ techniques such as anger-management training, emotion regulation strategies, and conflict resolution skills to help you achieve greater emotional control and healthier interpersonal relationships."
            />
            <FlipCard
              title="Anxiety and Panic Attack Therapy"
              description="Anxiety disorders, panic attacks, and phobias can be debilitating and significantly impact your daily life. Our therapists specialize in evidence-based treatments like cognitive-behavioral therapy (CBT), exposure therapy, and mindfulness-based interventions to help you manage and overcome these challenges. We work collaboratively with you to develop personalized strategies for managing symptoms, reducing negative thought patterns, and improving overall coping abilities."
            />
            <FlipCard
              title="Depressive Symptoms Counseling"
              description="Depressive symptoms can affect every aspect of your life, including your mood, energy levels, and overall well-being. Our depression counseling services aim to provide you with a supportive and non-judgmental environment to explore the underlying causes of your depressive symptoms and develop effective coping strategies. We employ evidence-based approaches like CBT, interpersonal therapy, and mindfulness-based interventions to help you manage symptoms, improve mood, and regain a sense of purpose and joy in life."
            />
            <FlipCard
              title="Relationship Counseling"
              description="Healthy relationships are essential for personal well-being and fulfillment. Our relationship counseling services address a wide range of issues, including communication challenges, trust concerns, intimacy problems, and conflict resolution. Through couples therapy, family therapy, or individual counseling, we help you develop effective communication skills, rebuild trust, and foster deeper emotional connections with your loved ones."
            />
            <FlipCard
              title="Grief and Loss Therapy"
              description="Healthy relationships are essential for personal well-being and fulfillment. Our relationship counseling services address a wide range of issues, including communication challenges, trust concerns, intimacy problems, and conflict resolution. Through couples therapy, family therapy, or individual counseling, we help you develop effective communication skills, rebuild trust, and foster deeper emotional connections with your loved ones."
            />
            <FlipCard
              title="Lifestyle Counseling"
              description="Unhealthy lifestyle habits can contribute to various mental and physical health problems. Our lifestyle counseling services are designed to help you identify and address unhealthy patterns, develop healthier habits, and achieve a more balanced and fulfilling lifestyle. We provide guidance on topics such as stress management, time management, work-life balance, healthy eating, and exercise routines to support your overall well-being."
            />
            <FlipCard
              title="Self-Esteem and Self-Confidence Building"
              description="Low self-esteem and low self-confidence can significantly impact your personal and professional life. Our counseling services focus on helping you develop a more positive self-image, challenge negative thought patterns, and build self-acceptance and self-compassion. Through evidence-based approaches like cognitive-behavioral therapy (CBT), mindfulness-based interventions, and self-esteem building exercises, we help you cultivate a healthier sense of self-worth and confidence."
            />
            <FlipCard
              title="Boundary Setting Counseling"
              description="Establishing healthy boundaries is essential for maintaining balanced relationships and protecting your emotional well-being. Our boundary-setting counseling services provide you with the tools and strategies to set and communicate clear boundaries, assert yourself effectively, and maintain healthy personal and professional relationships. We help you develop assertiveness skills, increase self-awareness, and build the confidence to establish and enforce boundaries that support your emotional and physical well-being."
            />
            <FlipCard
              title="Goal Setting and Achievement"
              description="Achieving your personal and professional goals can be a challenging and rewarding journey. Our goal-setting and achievement counseling services provide you with the guidance and support you need to identify your goals, develop a clear plan of action, and overcome obstacles along the way. We help you cultivate a growth mindset, build self-discipline, and develop the resilience to stay motivated and focused on your goals."
            />
            <FlipCard
              title="Career Development and Performance Enhancement"
              description="Our career development and performance enhancement counseling services are designed to help you achieve your professional goals and reach your full potential. Whether you're seeking career guidance, facing challenges in your current role, or looking to improve your overall performance, our therapists provide personalized support and evidence-based strategies to help you overcome obstacles, enhance your skills, and cultivate a fulfilling and successful career."
            />
            <FlipCard
              title="Life Transitions Counseling"
              description="Life transitions, such as starting a new job, getting married, becoming a parent, or experiencing a significant loss, can be challenging and emotionally demanding. Our life transition counseling services provide a supportive and non-judgmental environment to navigate these changes, process emotions, and develop effective coping strategies. We help you develop resilience, embrace new roles and responsibilities, and find meaning and purpose in your life's journey."
            />
            <FlipCard
              title="Emotional Regulation Therapy"
              description="Emotional regulation skills are crucial for maintaining healthy relationships, managing stress, and achieving overall well-being. Our emotional regulation counseling services focus on helping you develop strategies to recognize, understand, and effectively manage your emotions. Through techniques like mindfulness, cognitive-behavioral therapy (CBT), and emotion-focused therapy, we help you build emotional awareness, improve impulse control, and cultivate healthy coping mechanisms."
            />
            <FlipCard
              title="Self-Discovery and Personal Growth"
              description="Embarking on a journey of self-discovery and personal growth can be a transformative experience. Our self-discovery and personal growth counseling services provide a supportive and nurturing environment for you to explore your values, beliefs, strengths, and areas for growth. Through evidence-based approaches like existential therapy, mindfulness-based interventions, and self-exploration exercises, we help you cultivate self-awareness, develop a deeper understanding of your authentic self, and achieve greater fulfillment and meaning in your life."
            />
          </div>
          <div className="mt-10 rounded-2xl bg-[#4C7C7C] text-white p-4">
            <h2
              style={{ fontFamily: "bespoke-bold" }}
              className="font-semibold text-4xl text-center "
            >
              Why Choose Us?
            </h2>
            <p className="text-lg mt-10">
              At Wisemark Aura, we understand that seeking psychological therapy
              or counseling is a deeply personal and courageous decision. That's
              why we strive to provide a compassionate, supportive, and
              judgment-free environment where you can feel comfortable exploring
              your thoughts, emotions, and experiences. Here are a few reasons
              why we stand out:
            </p>
            <ul className="list-disc mx-4 text-lg mt-10">
              <li className="mb-4">
                <span className="font-semibold">
                  Comprehensive Range of Services: &nbsp;
                </span>
                We offer a comprehensive range of psychological therapy and
                counseling services tailored to the unique needs of young adults
                and adults. Whether you're struggling with stress, anxiety,
                depression, relationship issues, trauma, or any other mental
                health concern, we have the expertise to guide you through your
                challenges.
              </li>
              <li className="mb-4">
                <span className="font-semibold">
                  Evidence-Based Approaches: &nbsp;
                </span>
                We utilize evidence-based approaches, such as
                cognitive-behavioral therapy (CBT), mindfulness-based
                interventions, trauma-focused therapies, and emotion-focused
                therapies. These proven methods are grounded in scientific
                research and have been shown to be effective in addressing a
                wide range of mental health concerns.
              </li>
              <li className="mb-4">
                <span className="font-semibold">
                  Personalized Treatment Plans: &nbsp;
                </span>
                We understand that every individual is unique, with their own
                set of experiences, goals, and needs. That's why we take a
                personalized approach to treatment, creating customized
                treatment plans that are tailored to your specific circumstances
                and needs. We work collaboratively with you to develop
                strategies and coping mechanisms that resonate with your
                personal values and goals.
              </li>
              <li className="mb-4">
                <span className="font-semibold">
                  Safe and Supportive Environment: &nbsp;
                </span>
                We recognize that seeking therapy or counseling can be a
                vulnerable and emotional experience. That's why we strive to
                create a safe and supportive environment where you can feel
                comfortable openly expressing your thoughts and feelings without
                fear of judgment.
              </li>
              <li className="mb-4">
                <span className="font-semibold">
                  Commitment to Personal Growth: &nbsp;
                </span>
                At Wisemark Aura, we believe that personal growth and
                self-discovery are essential components of a fulfilling life. We
                are dedicated to helping you develop a deeper understanding of
                yourself, cultivate self-awareness, and achieve greater
                fulfillment and meaning in your life.
              </li>
              <li className="mb-4">
                <span className="font-semibold">
                  Convenient and Accessible Services: &nbsp;
                </span>{" "}
                We understand the importance of accessibility and convenience
                when it comes to seeking mental health services. That's why we
                offer flexible scheduling options and virtual or online therapy
                sessions to accommodate your busy lifestyle.
              </li>
            </ul>
          </div>
          <div>
            <img src={process} alt="Process" className="w-full rounded-2xl mt-8" />
          </div>
        </div>
      </section>
      <section className="my-10 max-w-screen-xl mx-auto px-4">
        <h1
          style={{ fontFamily: "bespoke-bold" }}
          className=" text-5xl text-[#4C7C7C] max-w-2xl font-bold"
        >
          Pricing
        </h1>
        <div className="flex flex-col md:flex-row md:flex-nowrap gap-10 items-stretch mt-4">
          <div className="relative w-full md:w-1/3 border-[6px] border-[#4C7C7C] bg-[#8CC7C6] bg-opacity-30 rounded-[30px] mt-10 md:mt-0 min-h-[300px]">
            <div className="w-full p-5 lg:p-10 flex flex-col justify-between h-full">
              <div className="md:p-4 my-2 rounded-full bg-[#8CC7C6]">
                <h3
                  style={{ fontFamily: "bespoke-bold" }}
                  className="text-[#4C7C7C] text-center font-semibold text-xl md:text-2xl lg:text-3xl"
                >
                  DISCOVERY SESSION
                </h3>
              </div>
              <ul className="list-disc mx-6">
                <li>
                  <p className="font-semibold">Free of cost</p>
                </li>
                <li>
                  <p className="font-semibold">30 min</p>
                </li>
                <li>
                  <p className="font-semibold">Online</p>
                </li>
                <li>
                  <p className="font-semibold">Flexible Scheduling</p>
                </li>
              </ul>
              <Link
                to="/contact"
                className="bg-[#8CC7C6] focus:ring-4 focus:outline-none focus:ring-blue-300 font-semibold text-xl rounded-full px-4 py-2 mt-4 text-center block mx-auto"
              >
                Book Session
              </Link>
            </div>
          </div>
          <div className="relative w-full md:w-1/3 border-[6px] border-[#4C7C7C] bg-[#8CC7C6] bg-opacity-30 rounded-[30px] mt-10 md:mt-0 min-h-[300px]">
            <div className="w-full p-5 lg:p-10 flex flex-col justify-between h-full">
              <div className="md:p-4 my-2 rounded-full bg-[#8CC7C6]">
                <h3
                  style={{ fontFamily: "bespoke-bold" }}
                  className="text-[#4C7C7C] text-center font-semibold text-xl md:text-2xl lg:text-3xl"
                >
                  INDIVIDUAL SESSION
                </h3>
              </div>
              <ul className="list-disc mx-6">
                <li>
                  <p className="font-semibold"> ₹ 500/-</p>
                </li>
                <li>
                  <p className="font-semibold">60 min</p>
                </li>
                <li>
                  <p className="font-semibold">Online</p>
                </li>
                <li>
                  <p className="font-semibold">Flexible Scheduling</p>
                </li>
              </ul>
              <button
                onClick={() => setShowBookingModal(!showBookingModal)}
                type="button"
                className="bg-[#8CC7C6] focus:ring-4 focus:outline-none focus:ring-blue-300 font-semibold text-xl rounded-full px-4 py-2 mt-4 text-center block mx-auto"
              >
                Book Session
              </button>
            </div>
          </div>
          <div className="relative w-full md:w-1/3 border-[6px] border-[#4C7C7C] bg-[#8CC7C6] bg-opacity-30 rounded-[30px] mt-10 md:mt-0 min-h-[300px]">
            <div className="w-full p-5 lg:p-10 flex flex-col justify-between h-full">
              <div className="md:p-4 my-2 rounded-full bg-[#8CC7C6]">
                <h3
                  style={{ fontFamily: "bespoke-bold" }}
                  className="text-[#4C7C7C] text-center font-semibold text-xl md:text-2xl lg:text-3xl"
                >
                  6 INDIVIDUAL SESSIONS
                </h3>
              </div>
              <ul className="list-disc mx-6">
                <li>
                  <p className="font-semibold"> ₹ 2500/- (Discount)</p>
                </li>
                <li>
                  <p className="font-semibold">60 min</p>
                </li>
                <li>
                  <p className="font-semibold">Online</p>
                </li>
                <li>
                  <p className="font-semibold">Flexible Scheduling</p>
                </li>
              </ul>
              <button
                onClick={() => setShowBookingModal(!showBookingModal)}
                type="button"
                className="bg-[#8CC7C6] focus:ring-4 focus:outline-none focus:ring-blue-300 font-semibold text-xl rounded-full px-4 py-2 mt-4 text-center block mx-auto"
              >
                Book Session
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Services;
