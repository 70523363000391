import React, { useState } from 'react';

const FlipCard = ({ title, description }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <div
      className={`card-container ${isFlipped ? 'flipped' : ''}`}
      onClick={isFlipped ? handleFlip : ()=> {}}
    >
      <div className="card-front">
        <div
          style={{ fontFamily: 'bespoke-bold' }}
          className="self-center font-bold text-4xl text-center leading-8 text-slate-500"
        >
          <div>{title}</div>
          <button  onClick={handleFlip} className="text-white bg-[#8CC7C6] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-xl px-4 py-2 text-center mt-6">
          Read more
        </button>
        </div>
      </div>
      <div className="card-back no-scroll-bar">
        <div className="m-4 text-xl font-medium text-gray-900 text-opacity-80">
          {description}
        </div>
      </div>
    </div>
  );
};

export default FlipCard;
