/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import logo from "../assets/images/logo.png";
import { Link } from 'react-router-dom';

function Navbar({setShowBookingModal, showBookingModal}) {
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  return (
<nav className=" fixed w-full z-50 top-0 start-0">
  <div className="bg-[#4C7C7C] max-w-screen-xl flex flex-wrap items-center justify-between xl:rounded-full mx-auto p-4">
  <a href="/" className="flex items-center space-x-3 rtl:space-x-reverse">
      <img src={logo} className="h-8" alt="logo" />
      <span className="self-center text-2xl font-semibold whitespace-nowrap sr-only">UnlockUrSelf</span>
  </a>
  <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
      <a href="/services" type="button" className="text-white bg-[#8CC7C6] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm px-4 py-2 text-center">Book session</a>
      <button  onClick={toggleMenu} data-collapse-toggle="navbar-sticky" type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200" aria-controls="navbar-sticky"  aria-expanded={isOpen ? 'true' : 'false'}>
        <span className="sr-only">Open main menu</span>
        <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15"/>
        </svg>
    </button>
  </div>
  <div className={`items-center justify-between w-full md:flex md:w-auto md:order-1  ${isOpen ? 'block' : 'hidden'}`} id="navbar-sticky">
    <ul className="flex flex-col py-4 md:p-0 mt-4 text-white font-medium md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0">
      <li>
        <Link to="https://wisemarkaura.blogspot.com/" target='_blank' onClick={() => setIsOpen(false)} className="block py-2 px-3 rounded md:p-0 relative after:bg-white after:absolute after:h-0.5 after:w-0 after:bottom-0 after:left-0 hover:after:w-full after:transition-all after:duration-300 cursor-pointe" aria-current="page">Blogs</Link>
      </li>
      <li>
        <Link to ="/about" onClick={() => setIsOpen(false)} className="block py-2 px-3 rounded md:p-0 relative after:bg-white after:absolute after:h-0.5 after:w-0 after:bottom-0 after:left-0 hover:after:w-full after:transition-all after:duration-300 cursor-pointe">About</Link>
      </li>
      <li>
        <Link to ="/director" onClick={() => setIsOpen(false)} className="block py-2 px-3 rounded md:p-0 relative after:bg-white after:absolute after:h-0.5 after:w-0 after:bottom-0 after:left-0 hover:after:w-full after:transition-all after:duration-300 cursor-pointe">Director’s Desk</Link>
      </li>
      <li>
        <Link to="/services" onClick={() => setIsOpen(false)} className="block py-2 px-3 rounded md:p-0 relative after:bg-white after:absolute after:h-0.5 after:w-0 after:bottom-0 after:left-0 hover:after:w-full after:transition-all after:duration-300 cursor-pointe">Services</Link>
      </li>
      <li>
        <Link to="/contact" onClick={() => setIsOpen(false)} className="block py-2 px-3 rounded md:p-0 relative after:bg-white after:absolute after:h-0.5 after:w-0 after:bottom-0 after:left-0 hover:after:w-full after:transition-all after:duration-300 cursor-pointe">Contact</Link>
      </li>
    </ul>
  </div>
  </div>
</nav>
  )
}

export default Navbar