import React from "react";
import ceo from "../assets/images/ceo.png";
import { Helmet } from "react-helmet";

function About() {
  return (
    <>
      <Helmet>
        <title>About Us | Wisemarkaura</title>
        <meta
          name="description"
          content="Learn more about our dedicated psychologist and our mission to provide compassionate and effective mental health services. Find out about our expertise, approach, and commitment to helping you achieve your goals."
        />
         <meta name="keywords" content="about shreyashi bhattacharya, psychologist, mission, expertise, approach, mental health services" />
      </Helmet>

      <section className="max-w-screen-xl mx-auto px-4">
        <div className="mt-36">
          <h1
            style={{ fontFamily: "bespoke-bold" }}
            className="text-[#4C7C7C] text-center text-4xl sm:text-7xl font-bold"
          >
            {" "}
            Our Mission & Vision
          </h1>
          <div className="p-4 bg-[#8CC7C6] rounded-3xl mt-4">
            <p className="text-gray-700 mt-6 text-center">
              At Wisemark Aura, our mission is to bridge the gap between
              individuals seeking mental and emotional well-being and the
              compassionate psychologists who can guide them. We are committed
              to providing a secure, convenient, and empathetic platform that
              empowers clients to connect with experienced psychologists,
              fostering a journey of self-discovery, healing, and growth.
              Through our platform, we aim to create a supportive community
              where individuals can find the guidance they need to navigate
              life's challenges and unlock their fullest potential.
            </p>
            <p className="text-gray-700 mt-6 text-center">
              Our vision is to create a world where seeking help for mental and
              emotional well-being is as natural as seeking medical care for
              physical health. We envision a future where anyone, regardless of
              their location, background, or circumstances, can access expert
              psychological assistance with ease. By leveraging technology,
              empathy, and the expertise of our psychologists, we strive to
              build a global network of empowered individuals who are equipped
              to overcome challenges, enhance their emotional resilience, and
              lead fulfilling lives.{" "}
            </p>
          </div>
        </div>
        <div className="my-20 items-start">
          <h1
            style={{ fontFamily: "bespoke-bold" }}
            className="text-[#4C7C7C] text-4xl sm:text-7xl font-bold text-center md:text-start"
          >
            Meet Our Psychologist
          </h1>
          <div className="">
            <div className=" sm:float-left p-5 sm:pb-10 sm:px-10 sm:pt-0">
              <img
                src={ceo}
                className="sm:max-w-72 h-auto mx-auto md:mx-0 md:mr-6 "
                alt="CEO"
              />
              <p className="text-center max-w-xs mx-auto font-semibold mt-4">
                Shreyasi Bhattacharya
              </p>
              <p className="text-center max-w-xs mx-auto font-semibold">
                Founder and Director, Wisemark Aura Private Limited
              </p>
              <p className="text-center max-w-xs mx-auto font-semibold">
                Psychologist | Advanced Cognitive Hypnotic Coach | NLP
                Practitioner
              </p>
            </div>
            <p className="text-gray-700 mt-6 md:text-left text-justify ">
              Meet Shreyasi Bhattacharya, a trailblazing psychologist with a
              vision to guide individuals towards personal empowerment and
              transformation. Shreyasi has embarked on a mission to help people
              overcome challenges, discover their inner potential, and lead more
              fulfilling lives. With a deep passion for psychology, a wealth of
              experience, and an unwavering commitment to positive change,
              Shreyasi is dedicated to providing a platform that empowers
              individuals to unlock their true selves and embrace their journey
              of growth.
            </p>
            <p className="text-gray-700 mt-6 md:text-left text-justify ">
              Shreyasi's journey in psychology has been marked by a profound
              understanding of the human psyche and an unyielding desire to make
              a difference in people's lives. With advanced degrees in
              Psychology from esteemed institutions, she has honed her skills
              through years of working with diverse populations, addressing a
              wide range of challenges from anxiety and depression to personal
              development and relationship dynamics.
            </p>
            <p className="text-gray-700 mt-6 md:text-left text-justify ">
              What sets Shreyasi apart is her genuine empathy, compassionate
              approach, and the ability to create a safe and supportive space
              for clients to explore their thoughts and emotions. She believes
              that within every challenge lies an opportunity for
              transformation, and her mission is to guide individuals towards
              recognizing their strengths, navigating obstacles, and embracing
              positive change.
            </p>
            <p className="text-gray-700 mt-6 md:text-left text-justify ">
              Shreyasi's dedication to innovation and accessibility led her to
              create "Wisemark Aura," a platform that seamlessly connects
              clients with experienced psychologists. Through this platform, she
              envisions a world where seeking psychological support is
              convenient, stigma-free, and empowering. Her goal is to empower
              individuals to take control of their well-being journey and to
              provide them with the tools, guidance, and resources to unlock
              their true potential.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default About;
