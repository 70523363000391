import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDkm8VwA5CkN3lLlB1L2zsWkjF6iOA2csU",
  authDomain: "wisemarkaura.firebaseapp.com",
  projectId: "wisemarkaura",
  storageBucket: "wisemarkaura.appspot.com",
  messagingSenderId: "137753155649",
  appId: "1:137753155649:web:e7009d0c7ac98da9b90ec0",
  measurementId: "G-X54C6C2HMH"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);