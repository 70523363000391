import React, { useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom'; // Import necessary components
import { PopupModal } from "react-calendly";
import {Toaster} from 'react-hot-toast';

import Navbar from './common/Navbar';
import Footer from './common/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Contact from './pages/Contact';
import DirectorDesk from './pages/DirectorDesk';

function ScrollToTop() {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return null;
}

function App() {
  const [showBookingModal, setShowBookingModal] = useState(false);
  return (
    <div className="">
    <Router>
    <ScrollToTop />
      <Navbar />
      <Routes>
      <Route  path="*" element={<Home setShowBookingModal={setShowBookingModal} showBookingModal={showBookingModal} />} />

        <Route  path="/" element={<Home setShowBookingModal={setShowBookingModal} showBookingModal={showBookingModal} />} />
        <Route  path="/about" element={<About />} />
        <Route  path="/director" element={<DirectorDesk />} />
        <Route  path="/services" element={<Services setShowBookingModal={setShowBookingModal} showBookingModal={showBookingModal} />} />
        <Route  path="/contact" element={<Contact />} />
      </Routes>
      <PopupModal
        url="https://calendly.com/psycheshrey/individual-session"
        /*
         * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
         * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
         */
        rootElement={document.getElementById("root")}
        text="Click here to schedule!"
        textColor="#ffffff"
        color="#4C7C7C"
        open={showBookingModal}
        onModalClose={() => {setShowBookingModal(false)}}
        // pageSettings={{
        //   backgroundColor: "#8CC7C6",
        //   textColor: '#FFFFFF'
        // }}
        styles={{
          backgroundColor: '#8CC7C6',
          color: 'white',
          outline: 'none',
          borderRadius: '9999px', // Use a high value for a rounded shape
          fontSize: '0.875rem', // Equivalent to text-sm in tailwind
          padding: '0.5rem 1rem', // Equivalent to px-4 py-2 in tailwind
          textAlign: 'center',
          fontWeight: '500', // Equivalent to font-medium in tailwind
        }}
      />
      <Footer />
    </Router>
    <Toaster />
  </div>
  );
}

export default App;
