import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const CustomDot = ({ onClick, active }) => {
  return (
    <>
      {active ? (
        <button
          onClick={onClick}
          className="bg-[#4C7C7C] rounded-full w-6 h-6 mx-1 flex items-center justify-center py-center"
        >
          <div className="w-2 h-2 rounded-full bg-[#FFF2C5]" />
        </button>
      ) : (
        <button
          onClick={onClick}
          className="bg-[#4C7C7C] rounded-full w-4 h-4 mx-1"
        ></button>
      )}
    </>
  );
};

function DirectorDesk() {
  return (
    <>
      <section className="my-32 max-w-screen-xl mx-auto px-4">
        <div className="w-full mt-10">
          <Carousel
            swipeable={true}
            draggable={false}
            showDots={true}
            arrows={false}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlaySpeed={1000}
            keyBoardControl={true}
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            className="w-full pb-12"
            dotListClass="custom-dot-list-style items-center"
            itemClass="carousel-item-padding-40-px px-4"
            customDot={<CustomDot />}
          >
            <div className="bg-[#4C7C7C] pb-2 rounded-2xl">
              <img
                src="https://media.istockphoto.com/id/1388115351/photo/shot-of-a-young-man-having-a-therapeutic-session-with-a-psychologist.jpg?b=1&s=612x612&w=0&k=20&c=4FjVEBmW5X0dCIaggJG4-xCNwmV3mXZF7-6OhCHjnDQ="
                alt="Thearapy"
                className=" aspect-[1.58] rounded-2xl max-h-72 w-full"
              />
            </div>
            <div className="bg-[#4C7C7C] pb-2 rounded-2xl">
              <img
                src="https://media.istockphoto.com/id/1365322553/photo/therapy-session-for-teens-close-up.jpg?s=612x612&w=0&k=20&c=8wpqswxwRzkwZ-7YuVqnRxKUcDS0-nV0aNPgmxl6Nlc="
                alt="Thearapy"
                className=" aspect-[1.58] rounded-2xl max-h-72 w-full"
              />
            </div>
            <div className="bg-[#4C7C7C] pb-2 rounded-2xl">
              <img
                src="https://img.freepik.com/free-photo/child-helping-his-disabled-mother_23-2149226821.jpg"
                alt="Thearapy"
                className=" aspect-[1.58] rounded-2xl max-h-72 w-full"
              />
            </div>
          </Carousel>
        </div>
        <div className="mt-14">
          <div className=" p-4 rounded-3xl sm:rounded-full bg-[#8CC7C6] max-w-3xl mx-auto ">
            <h1
              style={{ fontFamily: "bespoke-bold" }}
              className="text-[#4C7C7C] font-semibold text-4xl text-center"
            >
              From the Director’s Desk
            </h1>
          </div>
          <p className="text-justify sm:text-center text-gray-700 mt-8 text-xl">
            Welcome to my corner of healing and growth! I'm delighted to have
            you here.
          </p>
          <p className="text-justify sm:text-center text-gray-700 mt-2 text-xl">
            I'm Shreyasi Bhattacharya a dedicated and compassionate psychologist
            with a passion for guiding individuals on their journey towards
            mental and emotional well-being. With a deep understanding of the
            intricate workings of the human mind, I am committed to assisting
            you in overcoming challenges, nurturing resilience, and
            rediscovering the strength that resides within you.
          </p>
          <p className="text-justify sm:text-center text-gray-700 mt-2 text-xl">
            My professional journey in psychology has been shaped by a diverse
            range of experiences. Having earned my Master in Clinical
            Psychology, I embarked on a quest to merge the realms of academic
            knowledge with the practical nuances of real-life situations. Over
            the years, I've had the privilege of working with people from all
            walks of life, each story contributing to my rich tapestry of
            understanding.
          </p>
          <p className="text-justify sm:text-center text-gray-700 mt-2 text-xl">
            My therapeutic approach is rooted in empathy, respect, and
            collaboration. I believe that every individual's experience is
            unique, deserving of a tailored and holistic treatment plan. Whether
            you're grappling with anxiety, depression, relationship challenges,
            or seeking personal growth, my aim is to provide a safe space for
            self-exploration and transformation. Drawing from evidence-based
            practices such as Cognitive-Behavioral Therapy (CBT), Mindfulness,
            and Positive Psychology, I empower my clients to navigate
            difficulties and unlock their potential.
          </p>
          <p className="text-justify sm:text-center text-gray-700 mt-2 text-xl">
            Beyond clinical practice, I am an avid advocate for mental health
            awareness. I regularly engage in workshops, seminars, and community
            outreach programs to spread the message that seeking help is a sign
            of strength, not weakness. The stigma surrounding mental health can
            only be dismantled through education and open conversations, and I'm
            dedicated to playing my part in this movement.
          </p>
          <p className="text-justify sm:text-center text-gray-700 mt-2 text-xl">
            When I'm not in my therapeutic space, you can find me immersed in
            books, exploring what the world has in store for me by traveling, or
            spending quality time with my own support system of friends and
            family. I believe in the balance of work and play, and I strive to
            bring that philosophy into both my personal and professional life.
          </p>
          <p className="text-justify sm:text-center text-gray-700 mt-2 text-xl">
            Thank you for considering me as a partner in your journey towards
            healing and self-discovery. It would be an honor to walk alongside
            you, offering guidance, insight, and unwavering support. Remember,
            the first step towards transformation is often the hardest, but you
            don't have to take it alone. Together, we can pave the way for a
            brighter, more fulfilling future.
          </p>
        </div>
      </section>
    </>
  );
}

export default DirectorDesk;
