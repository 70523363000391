import { IconBrandInstagram, IconBrandLinkedin } from '@tabler/icons-react';
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/logo.png';

function Footer() {
  return (
    <footer className="bg-[#DAEAE9]">
      <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
        <div className="md:flex space-y-10 md:space-y-0 md:justify-between">
          <div className="md:min-w-70 lg:min-w-96 mb-6 md:mb-0">
            <Link to="/" className="flex items-center">
              <img src={logo} className="h-20 me-3" alt="Logo" />
            </Link>
          </div>
          <div>
            <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase">Company</h2>
            <ul className="text-gray-500 font-medium">
              <li className="mb-3">
                <Link to="/" className="hover:underline text-gray-500">Company</Link>
              </li>
              <li className="mb-3">
                <Link to="/about" className="hover:underline text-gray-500">About Us</Link>
              </li>
              <li className="mb-3">
                <Link to="/" className="hover:underline text-gray-500">Blog</Link>
              </li>
              <li className="mb-3">
                <Link to="/" className="hover:underline text-gray-500">Privacy Policy</Link>
              </li>
              <li className="mb-3">
                <Link to="/" className="hover:underline text-gray-500">Terms and Conditions</Link>
              </li>
              <li className="mb-3">
                <Link to="https://www.wisemarkaura.com/sitemap.xml" className="hover:underline text-gray-500">Sitemap</Link>
              </li>
            </ul>
          </div>
          <div>
            <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase">Resources</h2>
            <ul className="text-gray-500 dark:text-gray-400 font-medium">
              <li className="mb-3">
                <Link to="/" className="hover:underline text-gray-500">Help center</Link>
              </li>
              <li className="mb-3">
                <Link to="/contact" className="hover:underline text-gray-500">Contact Us</Link>
              </li>
            </ul>
          </div>
          <div>
            <ul className="text-gray-500 dark:text-gray-400 font-medium flex items-center gap-3">
              <li className="">
                <Link to="https://www.linkedin.com/company/wisemark-aura" target='_blank' className="hover:underline text-gray-500"><IconBrandLinkedin width={32} height={32} /></Link>
              </li>
              <li className="">
                <Link to="https://www.instagram.com/wisemark_aura?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" target='_blank' className="hover:underline text-gray-500"><IconBrandInstagram width={32} height={32} /></Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer