import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

// images import
import { Link } from "react-router-dom";
import hero from "../assets/images/hero.png";
import quote from "../assets/images/quote.png";
import service from "../assets/images/service.png";
import service_2 from "../assets/images/service_2.png";
import service_3 from "../assets/images/service_3.png";
import service_4 from "../assets/images/service_4.png";
import service_5 from "../assets/images/service_5.png";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const responsive2 = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 2,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const CustomDot = ({ onClick, active }) => {
  return (
    <>
      {active ? (
        <button
          onClick={onClick}
          className="bg-[#4C7C7C] rounded-full w-6 h-6 mx-1 flex items-center justify-center py-center"
        >
          <div className="w-2 h-2 rounded-full bg-[#FFF2C5]" />
        </button>
      ) : (
        <button
          onClick={onClick}
          className="bg-[#4C7C7C] rounded-full w-4 h-4 mx-1"
        ></button>
      )}
    </>
  );
};

const CustomArrowLeft = ({ onClick }) => {
  return (
    <button
      className="absolute bg-[#4C7C7C] w-12 h-12 flex items-center justify-center rounded-full"
      onClick={() => onClick()}
    >
      <ChevronLeftIcon
        height={32}
        width={32}
        strokeWidth={3}
        className="text-white"
      />
    </button>
  );
};

const CustomArrowRight = ({ onClick }) => {
  return (
    <button
      className="absolute right-0 bg-[#4C7C7C] w-12 h-12 flex items-center justify-center rounded-full"
      onClick={() => onClick()}
    >
      <ChevronRightIcon
        height={32}
        width={32}
        strokeWidth={3}
        className="text-white"
      />
    </button>
  );
};

function Home({ setShowBookingModal, showBookingModal }) {
  return (
    <div>
      <section className="sm:h-[600px] bg-[#4C7C7C] rounded-b-[60px] overflow-hidden">
        <div className=" relative py-16 max-w-screen-xl px-4 mx-auto flex items-center h-full">
          <img
            src={hero}
            className="sm:max-w-md aspect-square absolute top-20 right-0 h-auto"
            alt="Hero"
          />
          <div className=" z-10 space-y-4">
            <h1
              style={{ fontFamily: "bespoke-bold" }}
              className=" text-7xl md:text-9xl text-[#122828] max-w-2xl font-bold"
            >
              A Place To Talk It Out
            </h1>
            <p className="max-w-lg text-black font-inter ">
              Wisemark Aura is more than a platform; it's a supportive community
              dedicated to helping you unveil your potential and thrive.
              Together, we'll navigate the complexities of life, unlock new
              perspectives, and empower you to become the architect of your own
              transformation. Welcome to a world of self-discovery and
              empowerment – welcome to Wisemark Aura!
            </p>
            <a
              href="/services"
              type="button"
              className="text-white bg-[#8CC7C6] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm px-4 py-2 text-center"
            >
              Book Session
            </a>
          </div>
        </div>
      </section>
      <section className="mt-10 max-w-screen-xl mx-auto px-4">
        <h1
          style={{ fontFamily: "bespoke-bold" }}
          className=" text-5xl text-[#4C7C7C] max-w-2xl font-bold"
        >
          About Us
        </h1>
        <p className=" font-inter font-medium text-gray-700 mt-2">
          Welcome to Wisemark Aura, a place where you Discover Your Inner
          Potential! 
        </p>

        <p className=" font-inter font-medium text-gray-700 mt-2">
          Our platform offers comprehensive psychological assistance and
          guidance to help you navigate life's challenges, overcome obstacles,
          and discover your inner strength. With our experienced psychologist
          dedicated to your journey, we provide specialized therapeutic pathways
          tailored to your needs, addressing the mind, body, and spirit. Rest
          assured, your privacy is paramount on our secure platform.
        </p>
        <div className="w-full mt-10">
          <Carousel
            swipeable={true}
            draggable={false}
            showDots={true}
            arrows={false}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlaySpeed={1000}
            keyBoardControl={true}
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            className="w-full pb-12"
            dotListClass="custom-dot-list-style items-center"
            itemClass="carousel-item-padding-40-px px-4"
            customDot={<CustomDot />}
          >
            <div className="bg-[#4C7C7C] pb-2 rounded-2xl">
              <img
                src="https://media.istockphoto.com/id/1388115351/photo/shot-of-a-young-man-having-a-therapeutic-session-with-a-psychologist.jpg?b=1&s=612x612&w=0&k=20&c=4FjVEBmW5X0dCIaggJG4-xCNwmV3mXZF7-6OhCHjnDQ="
                alt="Thearapy"
                className=" aspect-[1.58] rounded-2xl max-h-72 w-full"
              />
            </div>
            <div className="bg-[#4C7C7C] pb-2 rounded-2xl">
              <img
                src="https://media.istockphoto.com/id/1365322553/photo/therapy-session-for-teens-close-up.jpg?s=612x612&w=0&k=20&c=8wpqswxwRzkwZ-7YuVqnRxKUcDS0-nV0aNPgmxl6Nlc="
                alt="Thearapy"
                className=" aspect-[1.58] rounded-2xl max-h-72 w-full"
              />
            </div>
            <div className="bg-[#4C7C7C] pb-2 rounded-2xl">
              <img
                src="https://img.freepik.com/free-photo/child-helping-his-disabled-mother_23-2149226821.jpg"
                alt="Thearapy"
                className=" aspect-[1.58] rounded-2xl max-h-72 w-full"
              />
            </div>
          </Carousel>
        </div>
      </section>
      <section className="mt-10 max-w-screen-xl mx-auto px-4">
        <h1
          style={{ fontFamily: "bespoke-bold" }}
          className=" text-5xl text-[#4C7C7C] max-w-2xl font-bold"
        >
          Our Services
        </h1>
        <div className="flex flex-col sm:flex-row items-stretch gap-8 mt-4">
          <div className="relative flex-1 w-full md:w-1/3 rounded-2xl bg-[#4C7C7C] overflow-hidden p-4">
            <img
              src={service}
              className="absolute top-0 right-0 ml-auto h-28"
              alt="cloud"
            />
            <div className="mt-28 z-50">
              <h2
                style={{ fontFamily: "bespoke-bold" }}
                className="text-white text-2xl font-bold"
              >
                Stress and Pain Management
              </h2>
            </div>
          </div>
          <div className="relative flex-1 w-full md:w-1/3 rounded-2xl bg-[#4C7C7C] overflow-hidden p-4">
            <img
              src={service_2}
              className="absolute top-0 right-0 ml-auto h-28"
              alt="cloud"
            />
            <div className="mt-28 z-50">
              <h2
                style={{ fontFamily: "bespoke-bold" }}
                className="text-white text-2xl font-bold"
              >
                Relationship Issues
              </h2>
            </div>
          </div>
          <div className="relative flex-1 w-full md:w-1/3 rounded-2xl bg-[#4C7C7C] overflow-hidden p-4">
            <img
              src={service_3}
              className="absolute top-0 right-0 ml-auto h-28 w-[90%] pt-4 pl-4"
              alt="cloud"
            />
            <div className="mt-28 z-50">
              <h2
                style={{ fontFamily: "bespoke-bold" }}
                className="text-white text-2xl font-bold"
              >
                Adjustment and Anger Issues
              </h2>
            </div>
          </div>
        </div>

        <div className="flex flex-col sm:flex-row items-stretch gap-8 mt-8">
          <div className="relative flex-1 rounded-2xl bg-[#4C7C7C] overflow-hidden p-4">
            <img
              src={service_4}
              className="absolute top-0 right-0 ml-auto h-28"
              alt="cloud"
            />
            <div className="mt-28 z-50">
              <h2
                style={{ fontFamily: "bespoke-bold" }}
                className="text-white text-2xl font-bold"
              >
                Low Self-Confidence, Low Self-Esteem, and Creating Boundaries
              </h2>
            </div>
          </div>
          <div className="relative flex-1 rounded-2xl bg-[#4C7C7C] overflow-hidden p-4">
            <img
              src={service_5}
              className="absolute top-0 right-0 h-28 w-full"
              alt="cloud"
            />
            <div id="pricing" className="mt-28 z-50">
              <h2
                style={{ fontFamily: "bespoke-bold" }}
                className="text-white text-2xl font-bold"
              >
                Personal Growth and Self-Discovery
              </h2>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-10 max-w-screen-xl mx-auto px-4">
        <h1
          style={{ fontFamily: "bespoke-bold" }}
          className=" text-5xl text-[#4C7C7C] max-w-2xl font-bold"
        >
          Pricing
        </h1>
        <div className="flex flex-col md:flex-row md:flex-nowrap gap-10 items-stretch mt-4">
          <div className="relative w-full md:w-1/3 border-[6px] border-[#4C7C7C] bg-[#8CC7C6] bg-opacity-30 rounded-[30px] mt-10 md:mt-0 min-h-[300px]">
            <div className="w-full p-5 lg:p-10 flex flex-col justify-between h-full">
              <div className="md:p-4 my-2 rounded-full bg-[#8CC7C6]">
                <h3
                  style={{ fontFamily: "bespoke-bold" }}
                  className="text-[#4C7C7C] text-center font-semibold text-xl md:text-2xl lg:text-3xl"
                >
                  DISCOVERY SESSION
                </h3>
              </div>
              <ul className="list-disc mx-6">
                <li>
                  <p className="font-semibold">Free of cost</p>
                </li>
                <li>
                  <p className="font-semibold">30 min</p>
                </li>
                <li>
                  <p className="font-semibold">Online</p>
                </li>
                <li>
                  <p className="font-semibold">Flexible Scheduling</p>
                </li>
              </ul>
              <Link
                to="/contact"
                className="bg-[#8CC7C6] focus:ring-4 focus:outline-none focus:ring-blue-300 font-semibold text-xl rounded-full px-4 py-2 mt-4 text-center block mx-auto"
              >
                Book Session
              </Link>
            </div>
          </div>
          <div className="relative w-full md:w-1/3 border-[6px] border-[#4C7C7C] bg-[#8CC7C6] bg-opacity-30 rounded-[30px] mt-10 md:mt-0 min-h-[300px]">
            <div className="w-full p-5 lg:p-10 flex flex-col justify-between h-full">
              <div className="md:p-4 my-2 rounded-full bg-[#8CC7C6]">
                <h3
                  style={{ fontFamily: "bespoke-bold" }}
                  className="text-[#4C7C7C] text-center font-semibold text-xl md:text-2xl lg:text-3xl"
                >
                  INDIVIDUAL SESSION
                </h3>
              </div>
              <ul className="list-disc mx-6">
                <li>
                  <p className="font-semibold"> ₹ 500/-</p>
                </li>
                <li>
                  <p className="font-semibold">60 min</p>
                </li>
                <li>
                  <p className="font-semibold">Online</p>
                </li>
                <li>
                  <p className="font-semibold">Flexible Scheduling</p>
                </li>
              </ul>
              <button
                onClick={() => setShowBookingModal(!showBookingModal)}
                type="button"
                className="bg-[#8CC7C6] focus:ring-4 focus:outline-none focus:ring-blue-300 font-semibold text-xl rounded-full px-4 py-2 mt-4 text-center block mx-auto"
              >
                Book Session
              </button>
            </div>
          </div>
          <div className="relative w-full md:w-1/3 border-[6px] border-[#4C7C7C] bg-[#8CC7C6] bg-opacity-30 rounded-[30px] mt-10 md:mt-0 min-h-[300px]">
            <div className="w-full p-5 lg:p-10 flex flex-col justify-between h-full">
              <div className="md:p-4 my-2 rounded-full bg-[#8CC7C6]">
                <h3
                  style={{ fontFamily: "bespoke-bold" }}
                  className="text-[#4C7C7C] text-center font-semibold text-xl md:text-2xl lg:text-3xl"
                >
                  6 INDIVIDUAL SESSIONS
                </h3>
              </div>
              <ul className="list-disc mx-6">
                <li>
                  <p className="font-semibold"> ₹ 2500/- (Discount)</p>
                </li>
                <li>
                  <p className="font-semibold">60 min</p>
                </li>
                <li>
                  <p className="font-semibold">Online</p>
                </li>
                <li>
                  <p className="font-semibold">Flexible Scheduling</p>
                </li>
              </ul>
              <button
                onClick={() => setShowBookingModal(!showBookingModal)}
                type="button"
                className="bg-[#8CC7C6] focus:ring-4 focus:outline-none focus:ring-blue-300 font-semibold text-xl rounded-full px-4 py-2 mt-4 text-center block mx-auto"
              >
                Book Session
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-10 max-w-screen-xl mx-auto px-4">
        <h1
          style={{ fontFamily: "bespoke-bold" }}
          className="text-center text-5xl text-[#4C7C7C] font-bold"
        >
          Dont take our word for it
        </h1>
        <div className="mt-10">
          <Carousel
            swipeable={true}
            draggable={false}
            showDots={false}
            arrows={true}
            responsive={responsive2}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlaySpeed={1000}
            keyBoardControl={true}
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            className="w-full pb-12"
            dotListClass="custom-dot-list-style items-center"
            itemClass="carousel-item-padding-40-px px-4"
            customLeftArrow={<CustomArrowLeft />}
            customRightArrow={<CustomArrowRight />}
          >
            <div className="h-full relative  bg-[#4C7C7C] bg-opacity-30 rounded-3xl p-8">
              <p>
                My experience of therapy with Shreyasi is life changing. She
                helped me look at the broader and better picture of life. I
                could actually visualise a better life because of her. She
                instilled confidence and self love in me. Forever grateful. She
                proved to be a saviour for me.
              </p>
              <p className=" font-inter font-semibold text-2xl text-[#122828] mt-10">
                K.J.
              </p>
              <img
                src={quote}
                alt="Quote"
                className=" absolute z-10 bottom-0 right-0 h-24 m-4"
              />
            </div>
            <div className="h-full relative  bg-[#4C7C7C] bg-opacity-30 rounded-3xl p-8">
              <p>
                Working with ma’am was my first therapy experience and I had
                several expectations coming from a psychology background. My
                experience was amazing, the techniques we did helped me and I
                was able to see the results within a week. I’m now better at
                dealing with situations which would give me immense anxiety.
                Overall, it was a great experience and I’ve felt great after her
                sessions. Thank you so much for helping me on my mental
                health journey!
              </p>
              <p className=" font-inter font-semibold text-2xl text-[#122828] mt-10">
                A.M.
              </p>
              <img
                src={quote}
                alt="Quote"
                className=" absolute z-10 bottom-0 right-0 h-24 m-4"
              />
            </div>
            <div className="h-full relative  bg-[#4C7C7C] bg-opacity-30 rounded-3xl p-8">
              <p>
                Ms. Shreyasi is an amazing therapist. She made sure that I had a
                safe-space where I could be myself. She helped me uncover a lot
                of things that I didn’t even know impacted my life! It is such
                an amazing journey towards healing, provided by her.
              </p>
              <p className=" font-inter font-semibold text-2xl text-[#122828] mt-10">
                A.J.
              </p>
              <img
                src={quote}
                alt="Quote"
                className=" absolute z-10 bottom-0 right-0 h-24 m-4"
              />
            </div>
            <div className="h-full relative  bg-[#4C7C7C] bg-opacity-30 rounded-3xl p-8">
              <p>
                I have been to therapy before, however, I never found a
                psychologist like Shreyasi ma’am. Since the first session, she
                felt like a ray of sunshine in my dark life. Being a guy, it was
                difficult for me to step into this, but, ma’am made sure that I
                could feel comfortable and validated. Hats off to her hard-work
                and dedication!
              </p>
              <p className=" font-inter font-semibold text-2xl text-[#122828] mt-10">
                D.G.
              </p>
              <img
                src={quote}
                alt="Quote"
                className=" absolute z-10 bottom-0 right-0 h-24 m-4"
              />
            </div>
          </Carousel>
        </div>
      </section>
    </div>
  );
}

export default Home;
